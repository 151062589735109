import React, {Component} from 'react'
import './App.scss'
import asyncLoading from 'react-async-loader'
import TitleBar from './components/TitleBar/TitleBar'
import {Route} from 'react-router-dom'
import Lobby from './components/Lobby/Lobby'
import Home from './components/Home/Home'
import UserContext from './context/UserContext'
import UserService from './service/UserService'
import Drawer from '@material-ui/core/Drawer/Drawer'
import NavDrawerContent from './components/drawer/NavDrawerContent'
import GameService from './service/GameService'
import Game from './components/Game/Game'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import RoundScore from './components/Game/RoundScore'
import GameSummary from './components/Game/GameSummary'
import {withRouter} from 'react-router'
import {createMuiTheme} from '@material-ui/core/styles'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import Settings from './components/Settings/Settings'
import Ranking from './components/Ranking/Ranking'
import Polygon from './helper/Polygon'
import GoogleMapsService from './service/GoogleMapsService'

import appConf from './conf/app-conf.json'
import {randomIndexFromWeightVector, randomInt, transformWeightVector} from './helper/helperfunctions'
import axios from 'axios'
import {streetViewCountries} from './static/StreetViewCountry'
import Stats from './components/Stats/Stats'
import Maps from './components/Maps/Maps'
import LocationGenerationService from './service/LocationGenerationService'


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2196f3'
    },
    secondary: {
      main: '#ab47bc'
    },
    standard: {
      main: '#f4f4f4'
    }
  }
})


class App extends Component {

  state = {
    selectedMenu: 'lobby',
    navDrawerOpen: false,
    loadingNewGame: false,
    newGameProgress: 0,
    background: `${Math.ceil(68 * Math.random())}.jpg`
  }

  componentDidMount() {
    if (this.props.location.pathname.match('/templates/login.html')) {
      this.props.history.push('/')
    }
    UserService.userInfo()
      .then(user => {
        this.setState({user})
      })

    axios.get(`${appConf.frontendUrl}/countries.geojson`)
      .then(res => {
        this.setState({countriesGeoJson: res.data})
      })

    axios.get(`${appConf.frontendUrl}/countryToAreaRatios.json`)
      .then(res => {
        this.setState({countryToAreaRatios: res.data})
      })
  }

  setBackground = background => {
    this.setState({
      background
    })
  }

  toggleNavDrawer = opened => () => {
    this.setState({navDrawerOpen: opened})
  }

  pickRandomLocation = (countries = null) => {
    if (this.state.countriesGeoJson == null) {
      throw new Error('GeoJSON file not loaded!')
    }
    if (countries == null) {
      throw new Error('Country list not provided!')
    }

    let country = countries[randomInt(0, countries.length)]

    console.log(country)

    return this.pickRandomLocationInCountry(country)
  }

  pickRandomLocationInCountry = countryCode3 => {
    let countryObj = this.state.countriesGeoJson.features.find(countryObj => countryObj.properties.ISO_A3 === countryCode3)

    if (countryObj == null) {
      throw new Error('Could not find country in GeoJSON file!')
    }

    let countryGeometry = countryObj.geometry

    let point = null

    if (countryGeometry.type === 'Polygon') {
      point = Polygon.getRandomPointInsidePolygon(countryGeometry.coordinates[0])
    } else {
      let areaRatios = this.state.countryToAreaRatios[countryCode3]
      let transformedAreaRatios = transformWeightVector(areaRatios, x => Math.pow(x, 1 / 2))
      let coordinates = countryGeometry.coordinates[randomIndexFromWeightVector(transformedAreaRatios)][0]
      point = Polygon.getRandomPointInsidePolygon(coordinates)
    }
    return {
      lat: point[1],
      lng: point[0]
    }
  }

  generateLocationsByArea = (n, totalLocations = n, streetViewService = new this.props.googleMaps.StreetViewService()) => {
    return new Promise(resolve => {
      if (n <= 0) {
        resolve([])
      } else {
        LocationGenerationService.generateLocationByArea()
          .then(generatedLocation => {
            if (generatedLocation == null) {
              resolve(null)
            } else {
              this.setState({
                newGameProgress: 100 * (1 - (n - 1) / totalLocations)
              })
              this.generateLocationsByArea(n - 1, totalLocations, streetViewService)
                .then(locations => {
                  if (locations == null) {
                    resolve(null)
                  }
                  resolve([...locations, {location: generatedLocation.location, country: generatedLocation.country}])
                })
            }
          })
      }
    })
  }

  generateLocationsByCountrySelection = (n, countries, totalLocations = n, streetViewService = new this.props.googleMaps.StreetViewService()) => {
    return new Promise(resolve => {
      if (n <= 0) {
        resolve([])
      } else {
        LocationGenerationService.generateLocationByCountrySelection(countries)
          .then(generatedLocation => {
            if (generatedLocation == null) {
              resolve(null)
            } else {
              this.setState({
                newGameProgress: 100 * (1 - (n - 1) / totalLocations)
              })
              this.generateLocationsByCountrySelection(n - 1, countries, totalLocations, streetViewService)
                .then(locations => {
                  if (locations == null) {
                    resolve(null)
                  }
                  resolve([...locations, {location: generatedLocation.location, country: generatedLocation.country}])
                })
            }
          })
      }
    })
  }

  generateLocationsByStatistic = (n, country, statistic, totalLocations = n, streetViewService = new this.props.googleMaps.StreetViewService()) => {
    return new Promise(resolve => {
      if (n <= 0) {
        resolve([])
      } else {
        LocationGenerationService.generateLocationByStatistic(country, statistic)
          .then(generatedLocation => {
            if (generatedLocation == null) {
              resolve(null)
            } else {
              this.setState({
                newGameProgress: 100 * (1 - (n - 1) / totalLocations)
              })
              this.generateLocationsByStatistic(n - 1, country, statistic, totalLocations, streetViewService)
                .then(locations => {
                  if (locations == null) {
                    resolve(null)
                  }
                  resolve([...locations, {location: generatedLocation.location, country: generatedLocation.country}])
                })
            }
          })
      }
    })
  }

  generateLocations = (n, locationGenerationType, countries, totalLocations = n, streetViewService = new this.props.googleMaps.StreetViewService()) => {
    if (locationGenerationType === 'BY_AREA') {
      return this.generateLocationsByArea(n, n, streetViewService)
    }
    if (locationGenerationType === 'BY_COUNTRY_SELECTION') {
      return this.generateLocationsByCountrySelection(n, countries, n, streetViewService)
    }
    if (locationGenerationType === 'BY_POPULATION') {
      return this.generateLocationsByStatistic(n, countries[0], 'POPULATION', n, streetViewService)
    }
  }

  startNewGame = (players, gameSettings, lobbyId) => {
    this.setState({
      loadingNewGame: true
    })

    this.generateLocations(gameSettings.numberOfRounds, gameSettings.locationGenerationType, gameSettings.countrySelection && gameSettings.countrySelection.countries)
      .then(locations => {
        if (locations != null) {
          GameService.newGame(
            players,
            gameSettings,
            lobbyId,
            locations.map(location => location.location),
            locations.map(location => location.country)
          )
            .then(game => {
              this.setState({
                loadingNewGame: false,
                newGameProgress: 0
              })
            })
        }
      })
  }

  titleBar = menuName => (
    <>
      <TitleBar menuName={menuName} openNavDrawer={this.toggleNavDrawer(true).bind(this)}/>
    </>
  )

  render() {
    let showBackgroundImage = ['', '/', '/lobby'].includes(this.props.location.pathname)

    return (
      <MuiThemeProvider theme={{...theme}}>
        <UserContext.Provider value={this.state.user}>
          <Route path="/" exact render={() => this.titleBar('Home')}/>
          <Route path="/lobby" exact render={() => this.titleBar('Lobby')}/>
          <Route path="/game" exact render={() => this.titleBar('Game')}/>
          <Route path="/game" exact render={() => this.titleBar('Game')}/>
          <Route path="/roundscore" exact render={() => this.titleBar('Globe Guesser')}/>
          <Route path="/gamesummary" exact render={() => this.titleBar('Game Summary')}/>
          <Route path="/ranking" exact render={() => this.titleBar('Ranking')}/>
          <Route path="/settings" exact render={() => this.titleBar('Settings')}/>
          <Route path="/stats" exact render={() => this.titleBar('Stats')}/>
          <Route path="/maps" exact render={() => this.titleBar('Maps')}/>
          <div
            className="appBackground"
            style={{
              backgroundImage: showBackgroundImage
                ? `url(${appConf.frontendUrl}/${this.state.background})`
                : ''
            }}>
            <div className={showBackgroundImage ? 'backgroundLayer' : ''}>
              <div className="appDrawer">
                <Drawer
                  className="appDrawerContainer"
                  variant="permanent"
                >
                  <NavDrawerContent countriesGeoJson={this.state.countriesGeoJson}/>
                </Drawer>
              </div>
              <div className="appContentArea">
                <Route path="/" exact render={() => (
                  <div className="homeDiv">
                    <Home loggedIn={!!this.state.user}/>
                  </div>
                )}>
                </Route>
                <Route path="/lobby" exact render={() => (
                  <>
                    {
                      this.state.loadingNewGame &&
                      <div className="newGameProgress">
                        <CircularProgress
                          variant="static"
                          value={this.state.newGameProgress}
                        />
                      </div>
                    }
                    <Lobby startNewGame={this.startNewGame.bind(this)}
                           setBackgroundImage={this.setBackground.bind(this)}
                           user={this.state.user}
                           googleMaps={this.props.googleMaps}
                           countriesGeoJson={this.state.countriesGeoJson}
                    />
                  </>
                )}>
                </Route>
                <Route path="/game" exact render={() => (
                  <>
                    <Game
                      googleMaps={this.props.googleMaps}
                      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                      loadingElement={<div style={{height: `100%`}}/>}
                      containerElement={<div style={{height: `400px`}}/>}
                      mapElement={<div style={{height: `100%`}}/>}
                      user={this.state.user}
                      countriesGeoJson={this.state.countriesGeoJson}
                    />
                  </>
                )}>
                </Route>
                <Route path="/roundscore" exact render={() => (
                  <>
                    <RoundScore
                      googleMaps={this.props.googleMaps}
                      user={this.state.user}
                    />
                  </>
                )}>
                </Route>
                <Route path="/gamesummary" exact render={() => (
                  <>
                    <GameSummary
                      googleMaps={this.props.googleMaps}
                    />
                  </>
                )}>
                </Route>
                <Route path="/ranking" exact render={() => (
                  <>
                    <Ranking/>
                  </>
                )}>
                </Route>
                <Route path="/stats" exact render={() => (
                  <>
                    <Stats user={this.state.user}/>
                  </>
                )}>
                </Route>
                <Route path="/settings" exact render={() => (
                  <>
                    <Settings user={this.state.user}/>
                  </>
                )}/>
                <Route path="/maps" exact render={() => (
                  <Maps googleMaps={this.props.googleMaps} countriesGeoJson={this.state.countriesGeoJson}/>
                )}/>
              </div>
            </div>
          </div>
        </UserContext.Provider>
      </MuiThemeProvider>
    )
  }
}

function mapScriptsToProps(props) {
  return {
    googleMaps: {
      globalPath: 'google.maps',
      url: `https://maps.googleapis.com/maps/api/js?key=${appConf.googleMapsApiKey}`,
      jsonp: true
    }
  }
}

export default withRouter(asyncLoading(mapScriptsToProps)(App))
